/* inicio */

.iconMenu {
    width: 30px;
    height: 30px;
}

.logo-imageMov {
    width: 62%;
    height: auto;
    display: inline-block;
    margin-left: 10%;
    padding-left: 10px !important;
}

.inset-y-0 .logo-imageMov {
    width: 44%;
    height: auto;
    display: inline-block;
    margin-left: 20px;
    margin-top: 22px;
}

.iconMenLateral {
    width: 20px;
    height: 20px;
}

.iconMenuLateral {
    width: 35px;
    height: 35px;
}

.movil .iconCarrito {
    margin-top: 5%;
    width: 46%;
}

.iconCarritoMovil {
    width: 49%;
    margin: 0 auto;
    margin-top: 2px;
    display: table;
}

.iconCarritoMovilLogin {
    width: 49%;
    margin: 0 auto;
    margin-top: 12px;
    display: table;
}

.movil .cantidadProductos {
    top: 0px;
    right: 10px;
}

.contIniciaSesion {
    padding-left: 10%;
    margin-top: 5px;
}

.iconUser {
    display: inline-block;
    width: 27px;
    margin-right: 10px;
    vertical-align: top;
}

.iniciarSesionMovil {
    color: #585858;
    font-size: 11px;
    line-height: 1.3;
    display: block;
    font-family: 'Roboto-Medium';
    text-decoration: none !important;
}

.header {
    padding-top: 10px;
}

.noPadding {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.contCarrito {
    padding-right: 15px;
}

.bannerMovil {
    position: absolute;
    top: 10px;
    left: 0px;
}

.contHomeMovil {
    padding-top: 10px;
    position: relative;
}

.titleLlaollao {
    z-index: 1;
    font-family: 'Bree Rg Bold';
    color: #fff;
    font-size: 30px;
    margin-top: 140px;
    padding-left: 30px;
}

.subTitleLlaollao {
    z-index: 1;
    font-family: 'Bree Rg';
    color: #fff;
    font-size: 13px;
    margin-top: -5px;
    padding-left: 30px;
}

.movil .categoria4.Granizados {
    margin-top: -20%;
}

.contInfoDeliveryMovil {
    padding: 0px 10px 0px 30px;
    font-family: 'Roboto';
    font-size: 13px;
    text-decoration: none;
    color: #848484;
}

.contBorderVerde {
    background-color: #8FD400;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: absolute;
    top: 10px;
    right: 0px;
    left: 0px;
    bottom: 0px;
}

.contCategoriasMovil {
    background-color: #F3F3F3;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    margin-top: 30px;
}

.contMenu {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 30px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.itemMenu {
    padding: 6px 15px 0px 15px;
    box-shadow: 2px 4px 10px 2px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 2px 4px 10px 2px rgb(0 0 0 / 12%);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    background-color: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.imgMenuMovil {
    display: block;
    width: 90%;
    margin-left: 5%;
}

.tituloMenuMovil {
    display: block;
    color: #585858;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    text-decoration: none;
    font-family: 'Bree Rg Bold';
}

.mobile-stores-menu.above {
    transition-delay: 0s;
    z-index: 999;
    box-shadow: 0px 0px 1px 0px rgb(77 77 77 / 90%);
}

.visible-mobile {
    display: block!important;
}

.mobile-stores-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f2f2f2;
    z-index: 9;
    transition-delay: .5s;
}

ul.menu-icons {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding: 0px !important;
    margin: 0px !important;
}

ul.menu-icons li {
    width: 33.3%;
    height: 60px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    margin-top: 5px;
    position: relative;
}

ul li {
    list-style-type: none;
}

ul.menu-icons li a {
    color: #585858;
    font-family: 'Bree Rg';
    text-transform: uppercase;
    font-size: 13px;
}

ul.menu-icons li:after {
    content: ' ';
    border: 1px solid #e6e6e6;
    height: 90%;
    position: absolute;
    right: 0;
    bottom: 8px;
}

.iconbot {
    background-repeat: round;
    width: 28px;
    height: 28px;
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2px;
}

ul.menu-icons li:last-child:after {
    content: ' ';
    border: 0;
}

.margenPDFMovil {
    padding: 0px 6% 0px 6%;
}

.iconbot.iconf-rest {
    background-image: url("assets/images/icon_restaurante.png");
}

.iconbot.iconf-menu {
    background-image: url("assets/images/icon_menu.png");
}

.iconbot.iconf-promotions {
    background-image: url("assets/images/icon_promocion.png");
}

.active .iconbot.iconf-rest {
    background-image: url("assets/images/icon_restaurante_select.png");
}

.active .iconbot.iconf-menu {
    background-image: url("assets/images/icon_menu_select.png");
}

.active .iconbot.iconf-promotions {
    background-image: url("assets/images/icon_promocion_select.png");
}

ul.menu-icons li .active {
    color: #8FD400;
}

.contAgregar {
    padding-bottom: 0px;
}

.duration-300 {
    transition-duration: 300ms;
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.translate-x-0 {
    --transform-translate-x: 0;
}

.transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.z-30 {
    z-index: 30;
}

.w-64 {
    width: 30rem;
}

.left-0 {
    left: 0;
}

.inset-y-0 {
    top: 0;
    bottom: 0;
}

.z-20 {
    z-index: 20;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.hidden {
    display: none;
}

.fixed {
    position: fixed;
}

.overflow-y-auto {
    overflow-y: auto;
}

.border-r-2 {
    border-right-width: 2px;
}

.bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    z-index: 99999;
}

.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.-translate-x-full {
    --transform-translate-x: -100%;
}

.transition-opacity {
    transition-property: opacity;
}

.side-navigation-panel-select-option {
    border: 0px !important;
    padding-left: 30px !important;
}

.navigationMovil ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navigationMovil .nav-item {
    padding: 0.3rem 1.5rem !important;
    padding-left: 22px !important;
}

.navigationMovil img {
    width: 35px;
    height: 35px;
}

.navigationMovil a {
    font-family: 'Bree Rg';
    font-size: 13px;
    color: #808080;
    display: block;
}

.navigationMovil .side-navigation-panel-select-option {
    padding: 0.30rem 1.5rem !important;
    padding-left: 22px !important;
}

.side-navigation-panel-select-option-text {
    font-family: 'Bree Rg';
    font-size: 13px;
    color: #808080;
}

.side-navigation-panel {
    margin-top: 20px;
}

.side-navigation-panel-select-wrap {
    padding: 3px 0px;
}


/* fin inicio */


/* inicio promociones */

.contPromociones {
    padding-bottom: 40px;
}

.contPromociones .carousel .slider-wrapper {
    margin-top: 5px !important;
}

.textPromociones {
    color: #585858;
    font-family: 'Bree Rg';
    font-size: 18px;
}


/* fin promociones */


/* inicio tiendas */

.nuestrasTiendas {
    color: #fff;
    font-family: 'Bree Rg Bold';
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
}

.contNuestrasTiendas {
    background-color: #8FD400;
}

.contListTiendas {
    background-color: #F3F3F3;
    min-height: 200px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: 10px;
    padding: 15px 15px 85px 15px;
}

.itemTiendaMovil {
    padding: 6px 15px 10px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin: 10px 10px 10px 10px;
    background-color: #fff;
    position: relative;
}

.tituloTiendaMovil {
    color: #8FD400;
    font-size: 18px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 5px;
    margin-top: 10px;
}

.arrowNext {
    width: 35px;
    height: 20px;
    position: absolute;
    right: -4px;
    top: 35px;
}


/* fin tiendas */


/* categorias */

.contCategoriaMainMovil {
    min-height: 100px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
}

.titleProducto {
    color: #585858;
    font-size: 26px;
    font-family: 'Bree Rg Bold';
    padding: 0px 20px;
    display: block;
}

.detalleProducto {
    color: #8FD400;
    font-size: 14px;
    font-family: 'Bree Rg';
    padding: 0px 20px;
    display: block;
}

.toppingsFreeMovil {
    font-family: 'Roboto';
    color: #8B8B8B;
    font-size: 14px;
    padding: 0px 20px;
}

.tabsMovil .react-tabs__tab-list {
    border-bottom: 0px !important;
    margin: 0px !important;
}

.tabsMovil .react-tabs__tab {
    color: #585858;
}

.tabsMovil .rowRegistro {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.tabsMovil .contMainItem {
    display: inline-block;
    vertical-align: top;
    padding: 5px;
}

.tabsMovil .nameToppingD {
    direction: ltr;
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.contImageMainMovil {
    position: relative;
    min-height: 300px;
}


/* fin categorias */

.contAddCarritoMovil {
    border-radius: 16px;
    position: relative;
    height: 185px;
}

.contContactenos .movilTabs .react-tabs__tab {
    font-size: 15px;
    padding: 6px 5px;
    font-family: 'Bree Rg';
    text-align: center;
    width: 33%;
}

.contAgregarBorderMovil {
    background-color: #fff;
    border-radius: 25px;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    height: 90px;
}

.contItems {
    overflow-x: hidden;
    max-height: 190px;
}

.movil .contDetalleCarrito {
    padding: 10px 10px 5px 13px;
}

.movil .itemToppingSelect {
    padding: 5px 0px;
}

.movil .descripcionCat {
    font-family: 'Bree Lt';
    color: #585858;
    font-size: 15px;
    display: block;
    width: 100%;
    padding: 0px 20px;
}

.imgCategoriaMovil {
    width: 100%;
    margin: 0px auto;
    margin-left: 10%;
    margin-top: 30%;
}

.movil .nameItemProducto {
    margin-top: 10px;
}

.movil .itemCategoria {
    margin-right: 0px;
    margin-top: 10px;
    padding-bottom: 10px;
}

.contToppingTabs .react-tabs__tab--selected {
    border-bottom: 3px solid #8FD400 !important;
}

.contToppingTabs.react-tabs__tab-list {
    border-bottom: 1.5px solid #cdcdcd !important;
}

.movil .itemToppingSelect .removeTopping {
    right: 6px;
}

.movil .linkDetalle {
    color: #585858;
    background-color: #F3F3F3;
    border-color: #F3F3F3;
    border-width: 0px;
    width: 100%;
    padding-top: 3px;
    height: 40px;
    font-size: 12px;
    font-family: 'Roboto-Medium';
    margin-top: 20px;
    text-decoration: none;
    border-radius: 6px;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    outline: 0 !important;
}

.movil .btn-agregar {
    text-decoration: none;
    height: 40px;
    font-family: 'Roboto-Medium';
}

.movil .contContactenos .react-tabs {
    margin-top: 0px;
}

.movil .contFormularioContactenosHtml {
    margin-top: 0px;
    padding: 5px 25px;
}

.textLeft {
    text-align: left !important;
}

.movilcontCarritoRight .titleCant1 {
    font-size: 18px;
}

.movilcontCarritoRight .numeroTopping {
    font-size: 12px;
}

.movilcontCarritoRight .sinSeleccionar {
    font-size: 13px;
}

.logoImageClubMovil {
    width: 60%;
    margin-top: 8px;
    margin-left: 20px;
}

.opcionesClub {
    margin-top: 20px;
}

.opcionesClub a {
    color: #808080;
    text-decoration: none;
}

.opcionesClub li {
    display: block;
    position: relative;
    padding: 10px 20px 10px 25px;
    color: #808080;
    font-size: 13px;
    font-family: 'Roboto';
}

.opcionesClub li:before {
    content: "*";
    position: absolute;
    left: 5px;
    top: 7px;
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 26px;
}

.movilRegistrate a {
    color: #808080 !important;
    font-family: 'Roboto-Medium';
}

.tituloInformacionPersonal {
    color: #585858;
    font-family: 'Bree Rg Bold';
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
}

.retornar {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 17px;
    left: 16px;
}

.movilFooter .popup-footer {
    font-size: 15px;
}

.contMainMovil {
    background-color: #ECECEC;
    min-height: 200px;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px;
}

.footerMovil {
    margin-top: 0px;
    text-align: center;
    font-family: 'Bree Rg';
}

.movilFooter .popup-footer a {
    font-size: 15px;
}

.tituloNuestrasTiendasMovil {
    color: #8FD400;
    font-size: 36px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 5px;
    margin-top: 20px;
    padding-left: 20px;
}
.regresarMovil {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: 'Bree Rg';
    margin-left: 10px;
}
.blog .categoriaMovil {
    color: #8FD400;
    font-size: 15px;
    font-family: 'Bree Rg Bold';
    text-align: center;
    margin-top: 15px;
}

.blog .tituloMovil {
    color: #585858;
    font-size: 25px;
    font-family: 'Bree Rg';
    text-align: center;
    margin-top: 5px;
}

.blog .detalleMovil {
    color: #585858;
    font-size: 14px;
    font-family: 'Roboto';
    text-align: center;
    margin-top: 5px;
    margin-bottom: 70px;
}

.contMascotaMovil {
    border: 1px solid #8FD400;
    border-radius: 6px;
}

.titleMascotaMovil {
    background-color: #8FD400;
    font-family: 'Bree Rg';
    width: 100%;
    color: #fff;
    position: absolute;
    text-align: center;
    left: 0px;
    bottom: 0px;
}

.btConsultaMovil {
    height: 40px;
    font-size: 16px;
    font-family: 'Bree Rg Bold';
    width: 90%;
    margin-left: 5%;
}

.altoMinimoMovil {
    min-height: 100vh;
}

.altoMinimoM .contDireccionMain {
    margin: 40px 20px 0px 20px;
}

.contNameUser {
    position: relative;
    margin-top: 40px;
    color: #585858;
    font-family: 'Bree Rg Bold';
    font-size: 20px;
    padding-right: 65px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.altoMinimoM .contDireccion {
    margin-top: 20px;
}

.contPaso {
    flex: 1;
    margin-left: 20px;
    margin-bottom: 16px;
}

.headerNavegacion {
    background-color: #F1F1F1;
    height: 78px;
    width: 100%;
    flex-direction: row;
}

.contCenter {
    align-items: center;
    justify-content: center;
}

.txPaso {
    color: #686868;
    font-size: 14px;
    padding-top: 10px;
    font-family: 'Roboto';
}

.contCargador {
    background-color: #DEDEDE;
    width: 97%;
    height: 6px;
    margin-top: 4px;
    border-radius: 12px;
    flex-direction: row;
}

.contProceso {
    background-color: #8FD400;
    height: 6px;
    width: 33%;
    border-radius: 12px;
}

.contProcesoDos {
    background-color: #8FD400;
    height: 6px;
    width: 66%;
    border-radius: 12px;
}

.contProcesoTres {
    background-color: #8FD400;
    height: 6px;
    width: 100%;
    border-radius: 12px;
}

.contEnd {
    align-items: flex-end;
    justify-content: center;
    margin-top: 4px;
}

.txPaginacion {
    color: #686868;
    font-size: 14px;
    font-family: 'Roboto';
}

.pasoDos {
    display: none;
}

.imgProductoMovil {
    width: 100px;
    height: 132px;
    margin-right: 10px;
    flex-grow: 0;
    flex-shrink: 0;
}

.pasoDos .contListDirecciones {
    page-break-after: always;
    display: flex;
    flex-flow: wrap;
}

.pasoDos .itemDireccion {
    padding: 5px 0px;
}

.pasoDos .txTotalOrden {
    width: auto;
}

.pasoDos .itemMetodoPago {
    margin-right: 0px;
    display: block !important;
    min-height: 86px;
}

.pasoDos .iconMetodoPago {
    display: block !important;
    margin: 0px auto;
}

.movil .puntoProductoLeal {
    font-size: 20px;
}

.movil .seleccionar {
    font-size: 12px;
    color: #585858;
    font-family: 'Roboto';
    padding-left: 10px;
    display: block;
    padding-right: 20px;
}

.nameProductoMovil {
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 18px;
    padding-right: 20px;
    padding-left: 0px;
}

.tituloAcercaDe {
    font-family: 'Bree Rg';
    font-size: 18px;
    color: #585858;
    margin-bottom: 10px;
}

.textoAcercaDe {
    font-family: 'Roboto';
    font-size: 14px;
    color: #585858;
    margin-bottom: 10px;
}

.teGustaria {
    font-family: 'Roboto';
    font-size: 14px;
    color: #8FD400;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
}

.overlap-fondovasitoMovil {
    align-items: flex-end;
    background-image: url(https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/page/acerca-de/acerca-de-new-shape-2@2x.png);
    background-size: 100% 100%;
    height: 220px;
    padding: 30px 0px 30px 0px;
    width: 80%;
    margin-left: 10%;
}

.numerolMovil {
    left: 83px;
    letter-spacing: 0;
    line-height: 64px;
    top: 35px;
    white-space: nowrap;
    font-family: 'Bree Rg';
}

.bree-regular-normal-sheen-green-64pxMovil {
    color: rgba(143, 212, 0, 1);
    font-size: 64px;
    font-weight: 400;
    font-style: normal;
}

.anios {
    font-family: 'Bree Rg';
    font-size: 36px;
    color: #585858;
    margin-left: 10px;
    padding-top: 10px;
}

.empleadosMovil {
    font-family: 'Bree Rg';
    font-size: 23px;
    color: #585858;
    margin-left: 10px;
    padding-top: 25px;
}

.ya-son-6-aos {
    font-family: 'Roboto';
    font-size: 16px;
    color: #585858;
    text-align: center;
    padding: 0px 6%;
}

.subTituloAcercade {
    font-family: 'Bree Rg Bold';
    font-size: 28px;
    color: #8FD400;
    padding-top: 0px;
}

.subTituloNuestro {
    font-family: 'Bree Rg';
    font-size: 24px;
    color: #585858;
    padding-top: 0px;
    margin-top: 30px;
}

.contToppingsCarrito .react-tabs__tab-list {
    margin-left: 10px !important;
}

/* precio delivery */
.contPrecioDeliveryMovil{
    border: 1px solid #8FD400;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 5%;
    display: flex;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #fff;
}
.motoMovil{
    height: 30px;
    margin-right: 5px;
    display: inline-block;
}
.tituloPromoDeliveryMovil{
    color: #8FD400;
    font-family: 'Bree Rg Bold';
    font-size: 20px;
    line-height: 1;
    margin-top: 6px;
}
.textPromocionMovil{
    color: #8FD400;
    font-size: 12px;
    font-family: 'Roboto-Medium';
    margin-top: 5px;
    font-weight: bold;
    display: block;
}
.titleCant1Movil{
    color: #585858;
    font-size: 23px;
    font-family: 'Bree Rg Bold';
}
.descripcionPromo{
    font-family: 'Bree Lt';
    color: #585858;
    font-size: 15px;
    display: block;
    width: 100%;
}
/* fin precio delivery */
.tabsPromoMovil .react-tabs__tab-list {
    margin: 0px !important;
    border-bottom: 1.5px solid #cdcdcd !important;
}

.tabsPromoMovil .react-tabs__tab {
    color: #585858 !important;
    width: auto;
    font-size: 1.125em;
    margin-right: 0px;
}
.tabsPromoMovil .react-tabs__tab--selected{
    background-color: transparent !important;
    border-color: transparent !important;
    color: #8FD400 !important;
    font-size: 1.125em;
    border-bottom: 3px solid #8FD400 !important;
}
.tabsPromoMovil .rowRegistro {
    width: 100%;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    white-space: nowrap;
    padding: 0px;
}
.tabsPromoMovil .contMainItem {
    display: inline-block;
    vertical-align: top;
    padding: 5px;
}
.tabsPromoMovil .contMainItem {
    display: inline-block;
    vertical-align: top;
    padding: 5px;
}
.tabsPromoMovil .react-tabs__tab-panel{
    background: none;
    background-color: #fff !important;
    border-radius: 0px;
    margin-bottom: 0px;
    box-shadow: none;
}
.tabsPromoMovil .nameToppingD {
    direction: ltr;
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.tabsPromoMovil .itemTarrinaPromo{
    background-color: transparent !important;
    border: 1px solid #fff;
}
.tabsPromoMovil .detalleCat2{
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-left:0px;
    margin-right: 0px;
}
.tabsPromoMovil .itemTarrinaPromo{
    width: 31%;
    margin-left: 3.5%;
    border-radius: 8px;
    padding-bottom: 10px;
    margin-top: 10px;
}
.tabsPromoMovil .itemTarrinaPromo:first-child{
    width: 31%;
    margin-left: 0%;
}