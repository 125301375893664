@import url('//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css');
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bree Rg';
    src: local('Bree Regular'), local('Bree-Regular'), url('./assets/fonts/Bree-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bree Rg Bold';
    src: local('Bree Bold'), local('Bree-Bold'), url('./assets/fonts/Bree-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local('Roboto Medium'), local('Roboto-Medium'), url('./assets/fonts/RobotoMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bree Lt';
    src: local('Bree Light'), local('Bree-Light'), url('./assets/fonts/Bree-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

*:focus {
    outline: none !important
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ulTerminos {
    padding-left: 45px;
}

.pPadding {
    padding-left: 30px;
}

.paqueteRifa {
    text-align: center;
    text-decoration: underline;
    margin-top: 20px;
}

.ulTerminos ul{
    padding-left: 20px;
}

.ulTerminos li{
    list-style-type: circle;
}

.tituloTerminos {
    margin-top: 20px;
}

.App-link {
    color: #61dafb;
}

.logo-image {
    width: 90%;
    height: auto;
    display: block;
    padding-top: 20px;
}

.header {
    padding-top: 20px;
}

.espacio {
    height: 100px;
    margin-top: 25px;
    background-color: #ECECEC;
    text-align: center;
    padding-top: 40px;
    font-family: 'Bree Rg';
}

.footer {
    margin-top: 25px;
    text-align: center;
    padding-top: 40px;
    font-family: 'Bree Rg';
    padding-bottom: 0px;
}

.columnaPrimero {
    position: fixed;
}

.gratis {
    color: #8FD400 !important;
    font-family: 'Roboto-Medium' !important;
    font-weight: bold;
}

#navbar {
    padding: 0;
    margin: 0;
    padding-top: 15px;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
}

#navbar div {
    position: relative;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
}

#navbar div a {
    display: block;
    font-size: 14px;
    text-decoration: none;
    color: #585858;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto-Medium';
    border-bottom: 3px solid #fff;
}

#navbar div a:hover {
    border-bottom: 3px solid #8FD400;
}

#navbar .activeMenu {
    border-bottom: 3px solid #8FD400;
}

#navbar ul ul {
    position: absolute;
    top: 20px;
    visibility: hidden;
}

#navbar ul ul li a {
    width: 115px;
    padding-left: 5px;
}

#navbar ul li:hover ul {
    visibility: visible;
}

#navbar>ul>li>a {
    text-align: center;
}

.contRedes {
    padding-bottom: 10px;
    padding-top: 5px;
    margin-top: 5px;
    border-right: 1px solid #B2B2B2;
}

.logo-fb {
    width: 12px;
    height: auto;
    margin-right: 15px;
    float: right;
}

.logo-tt {
    width: 21px;
    height: auto;
    margin-right: 15px;
    float: right;
}

.logo-ins {
    width: 25px;
    margin-right: 15px;
    height: auto;
    float: right;
}

.menuLateral {
    margin-top: 60px;
}

.menuLateral ul {
    list-style-type: none;
    margin: 0px 0px 0px 0px;
    padding: 0;
    overflow: hidden;
}

.activeMenuLateral {
    color: #8FD400 !important;
}

.activeMenuLateral img {
    filter: invert(65%) sepia(84%) saturate(894%) hue-rotate(33deg) brightness(99%) contrast(101%);
}

.menuLateral li {}

.menuLateral li a {
    display: block;
    color: #585858;
    text-align: left;
    padding: 5px 14px 5px 14px;
    min-height: 60px;
    font-size: 18px;
    text-decoration: none;
    font-family: 'Bree Rg';
    line-height: 50px
}

.menuLateral li a img {
    float: left;
    margin-right: 6%;
    width: 31px;
}

.menuLateral li a:hover {
    color: #000;
}

.contInfoDelivery {
    padding: 10px 0 10px 0;
    border-top: 1px solid #CFCFCF;
    border-bottom: 1px solid #CFCFCF;
    font-family: 'Roboto';
    font-size: 13px;
    text-decoration: none;
    color: #848484;
    padding-top: 2em;
    margin-top: 1.5em;
}

.imgDelivery {
    float: left;
    width: 23px;
    margin-right: 15px;
}

.imgHorarios {
    float: left;
    width: 19px;
    margin-right: 15px;
}

.espacioInfoDelivery {
    padding: 0px 0px 10px 0px;
    font-family: 'Roboto';
}

.espacioInfoDelivery span {
    display: block;
    font-family: 'Roboto';
}

.productoAgregado {
    color: #585858;
    font-size: 26px;
    font-family: 'Bree Rg Bold';
    text-align: center;
}

.nombreProductoAgregado {
    color: #585858;
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: 'Bree Rg';
}

.noOrden {
    color: #585858;
    font-size: 15px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: 'Bree Rg';
}

.horariosSpan {
    margin-left: 34px;
    font-family: 'Roboto-Medium';
    font-size: 13px !important;
}

.tituloHorarios {
    font-family: 'Roboto-Medium' !important;
    font-size: 13px !important;
}

.tituloHorario {
    font-family: 'Roboto-Medium' !important;
    font-size: 12px !important;
    display: block;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.logo-image-club {
    width: 92%;
    margin-top: 8px;
    cursor: pointer;
}

.contIniciaSesion {
    border-left: 1px solid #B2B2B2;
    border-right: 1px solid #B2B2B2;
    text-align: left;
    padding-left: 20%;
    margin-top: 9px;
    position: relative;
    cursor: pointer;
}

.iconCarrito {
    width: 49%;
    margin: 0 auto;
    margin-top: 12px;
    display: table;
}

.cantidadProductos {
    background: #585858;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-family: 'Bree Rg';
    position: absolute;
    top: 7px;
    right: 5px;
}

.titleMascotas {
    color: #8FD400;
    font-size: 20px;
    font-family: 'Bree Rg Bold';
    margin-top: 20px;
}

.contMascotas .circleTopping {
    margin-top: 5px;
}

.contMascotas .contMainItem {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}

.contCarrito {
    position: relative;
    cursor: pointer;
}

.iniciarSesion {
    color: #8FD400;
    font-family: 'Roboto-Medium';
    font-size: 14px;
    text-decoration: none !important;
}

.tiempoEntrega {
    color: #585858;
    font-family: 'Roboto';
    font-size: 13px;
    margin-bottom: 20px;
}

.tiempoEntrega span {
    color: #000;
    font-family: 'Roboto-Medium';
    font-size: 13px;
    margin-bottom: 20px;
}

.saludoUser {
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 14px;
    text-decoration: none !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    padding-right: 4px;
}

.linkIniciar {
    cursor: pointer;
    color: #585858;
    font-family: 'Roboto';
    font-size: 14px;
    text-decoration: none !important;
}

.linkIniciar:hover {
    color: #585858 !important;
}

.linkMore {
    font-size: 10px;
    margin-left: 5px;
}

.carousel.carousel-slider {
    padding-bottom: 30px;
}

.carousel .control-dots .dot {
    background-color: #D4D4D4 !important;
    width: 40px !important;
    height: 6px !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    opacity: 0.8 !important;
}

.carousel .slider-wrapper {
    /*margin-top: 65px !important;*/
}

.carousel .control-dots .dot.selected {
    background-color: #8FD400 !important;
}

.tituloCat1 {
    color: #8FD400;
    font-size: 24px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 10px;
}

.tituloTienda {
    color: #8FD400;
    font-size: 24px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 5px;
    margin-top: 10px;
}

.localAbierto {
    color: #fff;
    background-color: #8FD400;
    font-size: 11px;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 2px 10px;
    width: 72px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.localCerrado {
    color: #fff;
    background-color: #585858;
    font-size: 11px;
    font-family: 'Roboto';
    font-weight: bold;
    padding: 2px 10px;
    width: 72px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.tituloNuestrasTiendas {
    color: #8FD400;
    font-size: 36px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 5px;
    margin-top: 0px;
}

.tituloCat2 {
    color: #585858;
    font-size: 36px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 0px;
}

.tituloCat3 {
    color: #585858;
    font-size: 36px;
    text-align: right;
    font-family: 'Bree Rg Bold';
    margin-bottom: 0px;
}

.subTituloCat3 {
    color: #585858;
    font-size: 18px;
    text-align: right;
    font-family: 'Bree Lt';
    display: block;
    margin-top: 10px;
}

.itemCant1 {
    background-color: #ECECEC;
    min-height: 200px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 15px;
}

.contAcercaDe {
    background-color: #ECECEC;
    min-height: 200px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 15px;
    font-family: 'Bree Rg';
}

.regresar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: 'Bree Rg';
    margin-left: 0px;
}

.descripcionProductoModal {
    font-family: 'Roboto';
    color: #585858;
    font-size: 14px;
}

.contValorNutricional {
    border-radius: 8px;
    margin-top: 10px;
    padding: 12px;
    border: 1px solid #D9D9D9;
}

.contValorNutricional .col-6 {
    font-family: 'Roboto';
    color: #585858;
    font-size: 12px;
}

.titleAlergenos {
    font-family: 'Roboto-Medium';
    color: #585858;
    font-size: 12px;
    margin-top: 30px;
}

.titleAlergenosItems {
    font-family: 'Roboto-Medium';
    color: #585858;
    font-size: 12px;
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.titleAlergenosItems .acepto {
    font-size: 13px;
    font-family: 'Roboto';
}

.titleAlergenosItems .terminos {
    color: #8FD400;
    font-family: 'Roboto';
    font-size: 13px;
}

.titleAlergenos div {
    display: contents;
}

.contAgregarModal {
    margin-top: 10px;
}

.contAgregarModal .precioCat2 {
    float: right;
    margin-right: 15px;
    margin-top: 13px;
}

.contAgregarModal .btn-primary {
    float: right;
    width: 150px;
    margin-top: 17px;
}

.iconAlergenos {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    float: left;
}

.titleValoresNutricionales {
    font-family: 'Roboto-Medium';
    color: #585858;
    font-size: 12px;
}

.itemCant2 {
    background-color: #ECECEC;
    min-height: 200px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 15px 10px 15px 25px;
}

.itemCant3 {
    min-height: 190px;
    padding: 6px 15px 0px 15px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
}

.itemCantLateralRight {
    min-height: 190px;
    padding: 6px 15px 0px 15px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    -webkit-border-top-left-radius: 16px;
    -webkit-border-bottom-right-radius: 16px;
    -moz-border-radius-topleft: 16px;
    -moz-border-radius-bottomright: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-left: 10px;
}

.itemTienda {
    min-height: 490px;
    padding: 6px 15px 10px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin: 10px 10px 25px 10px;
    background-color: #fff;
}

.itemMain {
    min-height: 190px;
    padding: 18px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-bottom-right-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-bottomright: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-bottom: 20px;
    border: 1px solid #8FD400;
    background-color: #F6F5F0;
    margin-left: 10px;
}

.itemPromo {
    margin-left: 10px;
}

.itemPromo img {
    width: 100%;
}

.tamanioCant1 {
    color: #8FD400;
    font-size: 23px;
    font-family: 'Bree Rg Bold';
}

.titleCant1 {
    color: #585858;
    font-size: 23px;
    font-family: 'Bree Rg Bold';
}

.detalleCant1 {
    color: #585858;
    font-size: 12px;
    font-family: 'Roboto';
}

.precioCant1 {
    color: #585858;
    font-size: 18px;
    font-family: 'Roboto-Medium';
}

.observaciones {
    font-family: 'Roboto' !important;
    font-size: 13px !important;
}

.imgCant1 {
    width: 100%;
}

.imgTienda {
    width: 100%;
    margin-top: 10px;
}

.imgTiendaIconDireccion {
    width: 23px;
    height: auto;
    margin-right: 10px;
}

.imgTiendaIconHorario {
    width: 21px;
    height: auto;
    margin-right: 10px;
}

.imgCant2 {
    width: 30%;
    margin: 0px auto;
    display: block;
    margin-top: 20px;
}

.btn-agregar {
    color: #fff;
    background-color: #8FD400;
    border-color: #8FD400;
    border-width: 0px;
    width: 100%;
    font-size: 12px;
    margin-top: 20px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    outline: 0 !important;
}

.btn-agregar:hover {
    background-color: #79b403 !important;
    border-color: #79b403 !important;
    border-width: 0px;
    outline: 0 !important;
    text-decoration: none;
}

.btn-agregar:active {
    background-color: #79b403 !important;
    border-color: #79b403 !important;
    border-width: 0px;
    outline: 0 !important;
}

.btn-agregar:focus {
    background-color: #79b403;
    border-color: #79b403;
    border-width: 0px;
    outline: 0 !important;
    box-shadow: none !important;
}

.linkDetalle {
    width: 100%;
    margin-top: 10px;
    display: block;
    text-align: center;
    color: #585858;
    text-decoration: underline;
    font-family: 'Roboto';
    font-size: 12px;
    cursor: pointer;
}

.linkDetalle:hover {
    color: #585858;
}

.descripcionCant1 {
    color: #585858;
    font-family: 'Roboto';
    font-size: 12px;
    margin-top: 15px;
}

.contImageCat1 {
    position: absolute;
    right: 0px;
}

.contAdd {
    position: relative;
}

.contCant2 {
    margin-top: 20px;
}

.descripcionCat2 {
    font-family: 'Bree Lt';
    color: #585858;
    font-size: 18px;
}

.descripcionCat3 {
    font-family: 'Bree Lt';
    color: #585858;
    font-size: 16px;
    margin-left: 25px;
}

.sizeCat2 {
    color: #585858;
    font-size: 14px;
    font-family: 'Roboto-Medium';
    display: block;
    text-align: center;
    font-weight: bold;
}

.detalleCat2 {
    color: #585858;
    font-size: 13px;
    font-family: 'Roboto';
    display: block;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
}

.detalleCat3 {
    color: #585858;
    font-size: 12px;
    font-family: 'Roboto';
    display: block;
    text-align: right;
}

.precioCat2 {
    color: #585858;
    font-size: 22px;
    font-family: 'Roboto-Medium';
    display: block;
    margin-top: 20px;
    text-align: center;
}

.lineaVertical {
    position: absolute;
    width: 1px;
    height: 80%;
    background-color: #D5D5D5;
    right: 0px;
    top: 0px;
}

.tiendaAbierta {
    color: #fff;
    font-size: 14px;
    font-family: 'Roboto';
    display: block;
    margin-top: 20px;
    background-color: #8FD400;
    padding: 5px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    clear: both;
    margin-bottom: 15px;
}

.movil .tiendaAbierta {
    padding: 5px 8px;
    font-size: 12px;
    width: 30%;
    text-align: center;
}

.movil .tiendaCerrada {
    padding: 5px 8px;
    font-size: 12px;
    width: 30%;
    text-align: center;
}

.movil .rastreoPedido {
    padding: 5px 8px;
    font-size: 12px;
    width: 30%;
    text-align: center;
}

.movil .linkDirecciones {
    padding: 5px 8px;
    font-size: 12px;
    width: 33%;
    margin-right: 10px;
    display: flex;
}

.direccionShow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tiendaCerrada {
    color: #878787;
    font-size: 14px;
    font-family: 'Roboto';
    display: block;
    float: right;
    background-color: #F3F3F3;
    height: 30px;
    margin-top: 20px;
    padding: 5px 30px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    clear: both;
    margin-bottom: 15px;
}

.rastreoPedido {
    color: #fff;
    font-size: 14px;
    font-family: 'Roboto';
    display: block;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 20px;
    background-color: #8FD400;
    padding: 5px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    clear: both;
    margin-bottom: 15px;
}

.contBotonesTop {
    display: flex;
}

.clear {
    clear: both;
    display: block;
}

.imgMain {
    width: 75%;
}

.linkLocationTienda {
    color: #585858;
    font-size: 13px;
    font-family: 'Roboto';
    margin-left: 20px;
    margin-bottom: 5px;
}

.linkLocationTienda:hover {
    color: #2c2c2c;
    text-decoration: none;
}

.contTiendas {
    background-color: #ECECEC;
    min-height: 200px;
}

.descripcionTienda {
    color: #585858;
    font-size: 12px;
    font-family: 'Roboto';
}

.descripcionTiendaChild {
    color: #585858;
    font-size: 12px;
    font-family: 'Roboto';
    padding-left: 40px;
    display: block;
    min-height: 52px;
}

.descripcionTiendaChild .descripcionTiendaChild {
    min-height: 69px;
}

.detalleProductoModal {
    color: #585858;
    font-size: 12px;
    font-family: 'Bree Rg';
    padding-left: 40px;
    display: block;
}

.removePaddingLeft {
    padding-left: 0px !important;
}

.bloqueDetalleTienda {
    margin-top: 18px;
}

.closeModal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.imgLocation {
    width: 30px;
    top: -30px;
    position: absolute;
    margin: 0px auto;
    display: block;
    cursor: pointer;
}

.verMapa {
    display: flex;
    cursor: pointer;
    background-color: #ECECEC;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    height: 35px;
    margin-top: 25px;
    margin-bottom: 15px;
    text-align: center;
    font-family: 'Roboto-Medium';
    font-size: 14px !important;
    color: #585858 !important;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ReactModal__Overlay {
    z-index: 11111;
}

.contSpinner {
    padding: 10px;
    display: flex;
    justify-content: center;
}


/* editar cuenta */

.popupHeader .input label {
    font-size: 1.165rem;
    width: 100%;
    color: #585858;
    font-family: 'Roboto-Medium';
}

.popupHeader .input {
    font-size: 1.165rem;
}

.movilTabs {
    padding-left: 0px !important;
    margin-bottom: 20px !important;
}

.lineaHeader {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0px;
    top: 5px;
}


/* fin editar cuenta */

.css-1s2u09g-control {
    margin-bottom: 23px;
    margin-top: 5px;
    padding: 0.3em;
    padding-left: 10px;
    padding-right: 0px;
    border-radius: 4px !important;
    height: 40px;
    width: 100%;
    background-color: #F7F7F7 !important;
    border: 1px solid #CFCFCF;
    font-family: 'Roboto';
    font-size: 1.125em;
    flex-wrap: nowrap !important;
}

.css-1pahdxg-control {
    margin-bottom: 23px;
    margin-top: 5px;
    padding: 0.3em;
    padding-left: 10px;
    padding-right: 0px;
    border-radius: 4px !important;
    height: 40px;
    width: 100%;
    background-color: #F7F7F7 !important;
    border: 1px solid #CFCFCF;
    font-family: 'Roboto';
    font-size: 1.125em;
    flex-wrap: nowrap !important;
    box-shadow: none !important;
}

.css-1pahdxg-control:hover {
    border-color: #CFCFCF !important;
}

.css-1pahdxg-control:active {
    border-color: #CFCFCF !important;
}

.css-1pahdxg-control:not(:active) {
    border-color: #CFCFCF !important;
}

.css-14el2xx-placeholder {
    color: #000 !important;
    margin-left: 0px !important;
}

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.css-tj5bde-Svg {
    color: #000;
    width: 15px;
    height: 15px;
}

.noInput input[type="text"] {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding: 0px !important;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0px !important;
    height: auto;
    width: 100%;
    background-color: #F7F7F7;
    border: 0px !important;
    font-size: 1.125em;
}

.noInput input[type="text"]:focus {
    box-shadow: unset !important;
    outline: none;
}

.errorSelect .css-1s2u09g-control {
    border-color: #df2241;
    background: #ffe6e8 !important;
    margin-bottom: 0px;
}


/* crear cuenta */

.contRegistroRight {
    background-color: #8FD400;
}

.logoImageBlanco {
    width: 60%;
    height: auto;
    display: block;
    margin: 0px auto;
    margin-top: 20px;
}

.llaollaoVaso {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    bottom: 0px;
}

.removePadding {
    padding: 0px !important;
    min-height: 800px;
    position: relative;
}

.tituloCrear {
    color: #585858;
    font-family: 'Bree Rg Bold';
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 2.125em;
}

.subTituloCrear {
    color: #585858;
    font-family: 'Roboto';
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1em;
}

.react-tabs__tab--selected {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #8FD400 !important;
    font-family: 'Bree Rg Bold';
    font-size: 1.125em;
}

.boxRight {
    padding-right: 15px;
}

.boxLeft {
    padding-left: 15px;
}

.marcaRegistrada {
    color: #585858;
    font-family: 'Bree Rg';
    font-size: 16px;
    margin-top: 40px;
    text-align: center;
    position: relative;
    padding-left: 15px;
    display: inline;
}

.crearCuentaLinks a {
    text-align: center;
    font-family: 'Bree Rg';
    font-size: 14px;
    color: #585858;
    margin-top: 20px;
}

.marcaRegistrada span {
    font-size: 22px;
    padding-top: 10px;
    top: -10px;
    left: 0px;
    position: absolute;
}

.createCuenta .react-tabs__tab--selected {
    border-bottom: 3px solid #8FD400 !important;
}

.react-tabs__tab {
    color: #BCBCBC;
    font-family: 'Bree Rg Bold';
    font-size: 1.125em;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #808080 !important;
}

.rowRegistro {
    color: #585858;
    font-family: 'Roboto-Medium';
    font-size: 0.875em;
}

.rowRegistro i {
    color: #858585;
}

.tituloCampo {
    margin-top: 20px;
    font-family: 'Roboto';
}

.glyphicon-blackboard:before {
    content: "\e218";
}

.inputSelectBirthDay {
    display: inline-block;
}

.birthDay {
    width: 29%;
    margin-right: 1%;
}

.birthMonth {
    width: 40%;
}

.birthYear {
    width: 29%;
    margin-left: 1%;
}

.btnContinuarCrearCuenta {
    width: 200px;
    margin: 0px auto;
    display: block;
    margin-top: 20px;
    height: 40px;
    font-family: 'Roboto';
}

.btnContinuarDesactivado {
    background-color: #DBDBDB !important;
    color: #727272;
}

.btnContinuarDesactivado:active {
    background-color: #DBDBDB !important;
    color: #727272;
}

.btnContinuarDesactivado:hover {
    background-color: #DBDBDB !important;
    color: #727272;
}

.btnContinuarDesactivado:focus {
    background-color: #DBDBDB !important;
    color: #727272;
}

.react-tabs {
    margin-top: 50px;
}

.acepto {
    color: #585858;
    font-family: 'Roboto';
    font-size: 0.875em;
}

.dolar {
    font-family: 'Roboto';
    font-size: 1.125em;
    margin-left: 25px;
    margin-bottom: 15px;
    margin-right: 6px;
}

.terminos {
    color: #ED243D;
    font-family: 'Roboto';
    font-size: 0.875em;
}

.inputCondiciones {
    width: 270px;
    margin: 0px auto;
}

.headerHistorial {
    text-align: center;
    display: block;
    color: #AAAAAA;
    font-family: 'Roboto';
    font-size: 14px;
    position: relative;
}

.headerHistorial span {
    background-color: #ECECEC;
}

.lineaHeaderLeft {
    position: absolute;
    left: 10px;
    width: 25%;
    top: 9px;
    height: 1px;
    background-color: #AAAAAA;
}

.lineaHeaderRight {
    position: absolute;
    right: 10px;
    width: 25%;
    top: 9px;
    height: 1px;
    background-color: #AAAAAA;
}

.contHistorialItem {
    margin-top: 20px;
}

.contHistorial .imgProducto {
    margin-left: 30px !important;
}

.contHistorial .nameProducto {
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 20px;
    padding-left: 0px;
}

.nameProductoMain {
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 20px;
    padding-left: 0px;
}

.namePromo {
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 20px;
    padding-left: 0px;
}

.contHistorial .fechaCompra {
    color: #585858;
    font-family: 'Roboto';
    font-size: 12px;
    padding-left: 0px;
}

.contHistorial .toppings {
    font-family: 'Roboto-Medium';
    font-size: 12px;
    color: #585858;
    padding-left: 25px;
}

.contHistorial .toppingsItems {
    font-family: 'Roboto';
    font-size: 12px;
    color: #585858;
    padding-left: 25px;
}

.contHistorial .contCarritoProducto {
    position: relative;
}

.contHistorial .lineaHistorialFull {
    background-color: #C5C5C5;
    width: 1px;
    position: absolute;
    top: 5px;
    bottom: 18px;
    padding: 0px;
    right: 0px;
}

.contHistorial .rowRegistro {
    padding-bottom: 10px;
}


/* fin crear cuenta */


/* modal login */

.popup-header {
    position: absolute;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    padding: 2.5em 2em;
    background: #ECECEC;
    -webkit-box-shadow: 0 0 1.25rem 0.25rem rgba(98, 98, 98, 0.5);
    box-shadow: 0 0 1.25rem 0.25rem rgba(98, 98, 98, 0.5);
    margin-top: 0.7rem;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    z-index: 1500;
    text-transform: none;
    font-size: 14px;
    font-size: .875rem;
    line-height: 19px;
    line-height: 1.1875rem;
    font-family: 'Roboto';
    color: #626262;
    min-width: 30em !important;
    height: auto !important;
    white-space: normal;
    display: none;
}

@media (min-width: 60em) {
    .popup-header {
        font-size: 16px;
        font-size: 1rem;
        line-height: 22px;
        line-height: 1.375rem
    }
}

.popup-header li {
    padding: 0.5em 0
}

.popup-header[aria-hidden="true"] {
    display: none
}

.popup-header:after {
    content: "";
    display: block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    left: 50%;
    top: -10px;
    z-index: 11
}

.popup-header.popup--anchor-right {
    right: 0;
    left: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.popup-header.popup--anchor-right:after {
    left: auto;
    right: 3em
}

.popup-header.popup--anchor-left {
    left: 0;
    right: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.popup-header.popup--anchor-left:after {
    left: 3em;
    right: auto
}

.popup-header.popup--delivery {
    width: 24em;
    padding: 10px;
}

.popup-header.popup--delivery .block-paragraph {
    width: 78%;
}

.popup-header.popup--delivery .block-paragraph span {
    font-size: 0.8em;
    font-weight: normal;
}

.popup-header.popup--delivery .popup-delivery-carryout,
.popup-header.popup--delivery .popup-delivery-carryout .omnibar-change-to-carryout input[type=submit] {
    padding-top: 0;
    text-transform: uppercase;
}

.popup-header.popup--delivery .icon-store {
    padding-top: 0;
    margin-top: 5px;
}

.popup-header.popup--delivery .h4.title {
    padding-bottom: 0;
}

.popup-header[aria-hidden="true"] {
    display: none;
}

@media (max-width: 52em) {
    .popup-header.popup--delivery {
        left: auto;
        right: 1em;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        width: 92%;
        max-width: 30em
    }
    .popup-header.popup--delivery:after {
        left: auto;
        right: 3.9em
    }
}

.popup-header .title {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-family: 'Roboto';
    font-weight: bold;
}

.popup-header ul {
    padding-left: 0;
    list-style-type: none
}

.popup-header p {
    margin-bottom: 0;
    white-space: normal
}

.popup-header .popup-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
    grid-gap: 2em;
    margin: 1em 0
}

.popup-header .popup-delivery-carryout .edit-address,
.popup-header .popup-delivery-carryout .edit-address:hover {
    background: transparent;
    color: #4D4D4D;
    padding-right: 0px;
    font-size: 0.9em;
    font-family: 'Roboto';
    font-weight: normal;
    padding-left: 0;
    margin-left: 0;
    margin-right: 5px;
}

.popup-header .popup-delivery-carryout .omnibar-change-to-carryout {
    display: inline;
    margin-left: 0px;
}

.popup-header .popup-delivery-carryout .omnibar-change-to-carryout input[type=submit] {
    background: transparent;
    color: #4D4D4D;
    font-size: 0.9em;
    padding-left: 5px;
}

.popup-header .product-image {
    width: 100%;
    height: 95px;
    background-size: cover;
    background-position: 0px -55px;
    background-repeat: no-repeat;
    border-radius: 6px;
}

@media (min-width: 26.875em) {
    .popup-header .popup-block {
        display: block;
        width: 100%
    }
    .popup-header .popup-delivery-carryout {
        display: block;
        float: left;
        padding-top: 1em
    }
    .popup-header .popup-grid-top-spacing {
        padding-top: 3.75em
    }
    .popup-header p.block-paragraph {
        display: inline-block;
        float: none;
        width: 46.4%
    }
    .popup-header .icon-store {
        vertical-align: top;
        margin-top: 15px;
    }
    .popup-header p.info {
        float: right
    }
    .popup-header p.info.carry {
        width: 35%;
        font-size: 0.9em;
    }
}

.popup-header strong {
    display: block;
    color: #808080;
    font-size: 1.3em;
    text-transform: uppercase;
    font-family: 'Roboto';
}

.popup-header .inline-form {
    display: inline-block;
}

.popup-header .inline-form input[type=submit] {
    margin: 0;
    padding: 0;
}

.popup-header .input label {
    font-size: 1.165rem;
    width: 100%;
    color: #585858;
    font-family: 'Roboto-Medium';
}

.popup-header .input input {
    width: 100%;
}

.omnibar-nav-account-signedin .popup-header .logout-btn {
    margin: 0;
    padding: 0;
    float: right;
    margin-top: 5px !important;
    color: #4d4d4d;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

a.button-secondary.forgot-password-link {
    float: right;
    font-size: 1.165rem;
    padding-right: 5px;
    font-family: 'Roboto';
    font-weight: normal;
    color: #4D4D4D;
    text-transform: none;
    border: 0;
    cursor: pointer;
    display: block;
    padding: 0;
}

.popup-footer {
    font-size: 12px;
}

.popup-footer p {
    text-align: center;
    margin-top: 30px;
}

.popup-footer a {
    color: #8FD400 !important;
    font-family: 'Roboto-Medium';
}

.popup-footer a {
    padding: 0;
    color: #4D4D4D;
    margin-top: 5px;
    text-transform: none;
    font-size: 1.165rem;
    padding-right: 20px;
    display: inline;
}

input.button.button--green {
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 1.5em;
    font-family: 'Roboto';
    font-weight: bold;
}

.embedded-popup[aria-hidden="true"] {
    display: none
}

.btn-dupli,
.btn-dupli-p {
    background: #148f6a;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
}

.btn-dupli:hover,
.btn-dupli-p:hover {
    color: white;
}

.cat-group .grid-pizzas .block:after {
    content: ' ';
    clear: both;
    display: table;
}

.cat-group .grid-pizzas .product-label-block {
    margin: 20px 0;
    font-size: 1.5em;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-weight: bold;
}

ul.small-nav-list li a {
    font-size: 1.125em;
}

.selector-options[aria-hidden="true"],
.delivery-validator[aria-hidden="true"],
.carryout-validator[aria-hidden="true"] {
    display: none
}

.oPuedes {
    display: flex;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20px;
    font-size: 1.165rem;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.contSesionRedes {
    display: block;
    position: relative;
    border: 1px solid #7B7B7B;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    height: 40px;
}

.iconRedes {
    position: absolute;
    width: 20px;
    left: 15px;
    top: 9px;
}

.iconRedesFacebook {
    position: absolute;
    width: 12px;
    left: 19px;
    top: 9px;
}


/* fin modal login */


/*producto*/

.nameProducto {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}

.contNameProducto {
    height: auto;
}

.nameProducto .titleCant1 {
    color: #8FD400;
    font-size: 44px;
    margin-top: 20px;
    display: block;
    font-family: 'Bree Rg Bold';
}

.nameProducto .descripcionCat {
    display: block;
}

.toppingsFree {
    font-family: 'Roboto';
    color: #8B8B8B;
    font-size: 14px;
}

.contToppings {
    min-height: 640px;
}

.contToppings.tarrinaPanel {
    min-height: 660px;
}

.contToppings.sensacionesPanel {
    min-height: 540px;
}

.contToppings .react-tabs__tab {
    background: #fff;
    background-color: #fff !important;
    color: #8FD400 !important;
    border-radius: 5px 5px 0 0;
    margin-right: 15px;
}

.contToppings .react-tabs__tab--selected {
    background: #8FD400;
    background-color: #8FD400 !important;
    color: #fff !important;
}

.contToppings .react-tabs__tab-list {
    border-bottom: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
}

.contToppings .react-tabs__tab-panel {
    background: #fff;
    background-color: #fff !important;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    margin-bottom: 10px;
    -webkit-box-shadow: 4px 6px 15px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 4px 6px 15px -2px rgba(0, 0, 0, 0.25);
}

.contToppings .rowRegistro {
    padding: 0px 15px 15px 15px;
}

.agregaToppingsCarrito {
    color: #585858;
    font-family: 'Bree Rg';
    margin-top: 30px;
    margin-left: 10px;
    font-size: 18px;
}

.circleTopping {
    position: relative;
    width: 95%;
    -webkit-box-shadow: 4px 5px 8px 3px rgb(0 0 0 / 25%);
    box-shadow: 4px 5px 8px 3px rgb(0 0 0 / 25%);
    border-radius: 50%;
    margin-top: 20px;
}

.circleTopping:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.contentTopping {
    position: absolute;
    width: 100%;
    height: 100%;
}

.nameTopping {
    color: #585858;
    font-size: 13px;
    text-align: center;
    line-height: 1.1;
    font-family: 'Roboto';
    margin-top: 10px;
}

.imgTopping {
    width: 88%;
    height: 88%;
    margin-top: 6%;
    margin-left: 6%;
    border-radius: 50%!important;
}

.masMenos {
    background-color: #8FD400;
    width: 22px;
    height: 22px;
    position: absolute;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    line-height: 1.3;
    font-size: 15px;
}

.contImageMain {
    position: relative;
}

.imgToppingMain {
    width: 100%;
    height: 100%;
}

.contInfoImageMain {
    position: absolute;
    width: 100%;
    top: 0px;
}

.nutricionalInfo {
    padding-bottom: 20px;
}

.valores {
    color: #585858;
    font-family: 'Roboto-Medium';
    font-size: 12px;
}

.itemValorNutricional {
    display: inline-block;
    padding: 8px 8px;
    margin: 5px 8px 7px 0px;
    font-family: 'Roboto';
    font-size: 11px;
    width: 30%;
    border: 1px solid #B8B8B8;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.contCarritoRight {
    background: #fff;
    position: relative;
    background-color: #fff !important;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    margin-bottom: 30px;
    -webkit-box-shadow: 4px 6px 15px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 4px 6px 15px -2px rgba(0, 0, 0, 0.25);
}

.contAddCarrito {
    border-radius: 16px;
    position: relative;
    height: 255px;
}

.contDetalleCarrito {
    padding: 10px 15px;
}

.contDetalleCarrito .titleCant1 {
    color: #8FD400;
    font-size: 24px;
    margin-top: 5px;
    display: block;
    border-bottom: 1px solid #9C9C9C;
    font-family: 'Bree Rg Bold';
}

.contToppingExtra {
    height: 15px;
    position: relative;
}

.modificarCantidad {
    background-color: #F5F8FF;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    float: right;
    border: 1px solid #BDBBBB;
    color: #007A53;
    line-height: 1.9;
    margin-top: 15px;
}

.cantidad {
    color: #000000;
    font-family: 'Bree Rg Bold';
    float: right;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.contMainItem {
    cursor: pointer;
    position: relative;
}

.contToppingExtraBorder {
    background-color: #8FD400;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: -25px;
}

.contAgregarBorder {
    background-color: #fff;
    border-radius: 25px;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    height: 150px;
}

.cantidadToppingExtra {
    color: #fff;
    font-family: 'Roboto-Medium';
    font-size: 13px;
    text-align: center;
    display: block;
    margin-top: 15px;
}

.contAgregar {
    position: relative;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.txTotal {
    color: #929292;
    font-family: 'Roboto-Medium';
    font-size: 11px;
    text-align: right;
    width: 100%;
    margin-top: 5px;
}

.total {
    color: #585858;
    font-family: 'Roboto';
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    width: 150px;
}

.subTotal {
    color: #585858;
    font-family: 'Roboto';
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    width: 150px;
}

.subTotalCarrito {
    color: #585858;
    font-family: 'Roboto';
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    width: 100px;
}

.noHayOrdenes {
    text-align: center;
    font-family: 'Bree Lt';
    color: #8FD400;
    font-size: 18px;
    display: block;
    margin: 20px 0px;
}

.noOrdenes {
    display: block;
    margin: 0px auto;
    margin-top: 30px;
}

.subTotalCarritoDos {
    color: #585858;
    font-family: 'Roboto';
    font-size: 17px;
    text-align: center;
    width: 100px;
}

.iconMas {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    color: #8FD400;
    margin-right: 5px;
    border-radius: 50%;
    line-height: 1.1;
}

.itemToppingSelect {
    padding: 10px 0px;
}

.itemToppingSelect .itemToppingTitle {
    position: relative;
}

.itemToppingSelect .punto {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #8FD400;
    border-radius: 50%;
    left: 0px;
    top: 8px;
}

.itemToppingSelect .numeroTopping {
    font-size: 14px;
    color: #929292;
    font-family: 'Roboto-Medium';
    padding-left: 10px;
}

.itemToppingSelect .seleccionar {
    font-size: 14px;
    color: #585858;
    font-family: 'Roboto';
    padding-left: 10px;
    display: block;
    padding-right: 20px;
}

.itemToppingSelect .sinSeleccionar {
    font-size: 14px;
    color: #B4B4B4;
    font-family: 'Roboto';
    padding-left: 10px;
    display: block;
}

.itemToppingSelect .removeTopping {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
}


/* fin producto */


/* categorias */

.contCategoriaMain {
    background-color: #ECECEC;
    min-height: 200px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding-right: 0px;
}

.contCategoria {
    position: relative;
    padding: 15px 48px 10px 30px;
    min-height: 260px;
}

.contTextCategoria {
    position: absolute;
    margin-left: 25px;
    width: 90%;
}

.descripcionCat {
    font-family: 'Bree Lt';
    color: #585858;
    font-size: 18px;
    display: block;
    width: 65%;
}

.tituloCat {
    color: #8FD400;
    font-size: 60px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 10px;
    width: 65%;
    display: block;
}

.itemCantList {
    background-color: #ECECEC;
    min-height: 200px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 15px;
    margin: 0px !important;
}

.imgCategoria {
    width: 50%;
    margin: 0px auto;
    position: absolute;
    margin-left: 22%;
    margin-top: -40%;
}

.itemCategoria {
    min-height: 190px;
    padding: 6px 15px 0px 15px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    position: relative;
    margin-top: 100px;
    background-color: #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    margin-right: 20px;
}

.paddingCategoria {
    padding: 15px 15px 10px 30px;
}

.nameItemProducto {
    margin-top: 30%;
    text-align: center;
}

.itemCategoria .detalleCant1 {
    text-align: center;
}

.itemCategoria .precioCant1 {
    text-align: center;
}


/* fin categorias */


/* blog */

.blog {}

.blog .categoria {
    color: #8FD400;
    font-size: 18px;
    font-family: 'Bree Rg Bold';
    text-align: center;
    margin-top: 15px;
}

.blog .titulo {
    color: #585858;
    font-size: 32px;
    font-family: 'Bree Rg';
    text-align: center;
    margin-top: 5px;
}

.aceptamosTarjeta {
    margin-right: 20px;
    align-items: flex-end;
    text-align: right;
    margin-top: 10px;
}

.aceptamosTarjetaMovil {
    margin-left: 15px;
    text-align: left;
    margin-top: 10px;
}

.blog .detalle {
    color: #585858;
    font-size: 16px;
    font-family: 'Roboto';
    text-align: center;
    margin-top: 5px;
    margin-bottom: 70px;
}

.rowRegistro .detalle {
    color: #585858;
    font-size: 16px;
    font-family: 'Roboto';
    text-align: left;
    margin-top: 15px;
    margin-bottom: 0px;
}

.blog ul {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    list-style-type: none;
}

.blog a {
    text-decoration: none !important;
}

.blog a:active {
    text-decoration: none;
}

.blog ul li {
    color: black;
    float: left;
    font-family: 'Bree Rg';
    text-decoration: none;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
}

.blog ul li a {
    display: block;
    padding: 8px 16px;
}

.blog ul li.active {
    background-color: #010201;
    color: white;
    border: 1px solid #4CAF50;
}

.blog ul li:hover:not(.active) {
    background-color: #ddd;
}

.blog .selected {
    background-color: #ced4da !important;
    border-color: #ced4da !important;
}

.blog .previous {
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.blog .next {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.blog .paginacion {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    display: none;
}

.blogLateral {}

.blogLateral .row {
    margin-left: 4%;
}

.blogLateral .titulo {
    color: #585858;
    font-size: 22px;
    font-family: 'Bree Rg';
    text-align: left;
    margin-top: 5px;
}

.blogLateral .detalle {
    color: #585858;
    font-size: 15px;
    font-family: 'Roboto';
    text-align: left;
    margin-top: 5px;
    margin-bottom: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.itemBlog {
    cursor: pointer;
}

.itemBlog:hover {
    cursor: pointer;
}

.itemBlog:hover .titulo {
    text-decoration: underline;
}

.imgBanner {
    width: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 0px;
}

.redesShare {
    position: absolute;
    bottom: 15px;
    right: 25px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.redesShare button {
    margin-left: 5px;
}

.redesShare span {
    margin-right: 10px;
    color: #585858;
    font-family: 'Roboto';
    font-size: 13px;
    display: inline-block;
}

.conFooterRedes {
    margin-bottom: 50px;
}

.conFooterRedes .btTwitter {
    background-color: #00aced !important;
    padding-right: 10px !important;
    color: #fff !important;
    font-family: 'Bree Rg' !important;
    font-size: 14px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 15px;
}

.conFooterRedes .btFacebook {
    background-color: #3b5998 !important;
    padding-right: 10px !important;
    color: #fff !important;
    font-family: 'Bree Rg' !important;
    font-size: 14px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 15px;
}

.conFooterRedes .btEmail {
    background-color: #7f7f7f !important;
    padding-right: 10px !important;
    color: #fff !important;
    font-family: 'Bree Rg' !important;
    font-size: 14px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 15px;
}

.conFooterRedes span {
    vertical-align: middle;
}


/*cuenta modal */

.modalCuenta {
    min-width: 180px !important;
}

.headerTuCuenta {
    font-family: 'Bree Rg Bold';
    font-size: 16px;
    margin-bottom: 10px;
}

.modalCuenta .linkIniciar {
    display: block;
    font-size: 13px;
    padding: 4px 0px;
}

.modalCuenta .linkIniciar:hover {
    color: #8FD400 !important;
    text-decoration: underline !important;
}

.montoMinimo {
    color: #df2241;
    margin-top: 10px;
    font-family: 'Bree Rg';
}

.modalCuentaMovil {
    min-width: 180px !important;
}

.headerTuCuenta {
    font-family: 'Bree Rg Bold';
    font-size: 16px;
    margin-bottom: 10px;
}

.modalCuentaMovil .linkIniciar {
    display: block;
    font-size: 13px;
    padding: 8px 0px;
}

.modalCuentaMovil .linkIniciar:hover {
    color: #8FD400 !important;
    text-decoration: underline !important;
}


/* cuenta modal */


/* fin blog */


/* inicio contactenos */

.contContactenos {}

.contContactenos .react-tabs {
    margin-top: 20px;
}

.contContactenos .react-tabs__tab--selected {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #585858 !important;
    border-bottom: 3px solid #8FD400 !important;
    font-family: 'Bree Rg Bold';
    font-size: 1.125em;
}

.contContactenos .react-tabs__tab {
    color: #BCBCBC;
    font-family: 'Bree Rg Bold';
    font-size: 22px;
}

.conInfoContactenos {
    padding: 20px 20px;
}

.datosContacto {
    color: #585858;
    font-family: 'Bree Rg Bold';
    font-size: 18px;
}

.descripcionContacto {
    color: #585858;
    font-family: 'Roboto';
    font-size: 14px;
}

.iconContactenos {
    width: 45px;
    height: 45px;
    display: inline-block;
    vertical-align: top;
    filter: invert(68%) sepia(43%) saturate(1419%) hue-rotate(34deg) brightness(100%) contrast(101%);
}

.iconContactenosT {
    width: 43px;
    height: 43px;
    display: inline-block;
    vertical-align: top;
    margin: 0px 10px 0px 0px;
    filter: invert(66%) sepia(87%) saturate(1549%) hue-rotate( 48deg) brightness(102%) contrast(101%);
}

.iconContactenosE {
    width: 35px;
    height: 35px;
    display: inline-block;
    vertical-align: top;
    margin: 0px 10px 0px 5px;
}

.contDataContactenos {
    display: inline-block;
}

.contDataContactenos .tituloTelefono {
    display: block;
    color: #585858;
    font-family: 'Bree Lt';
    font-size: 12px;
}

.contDataContactenos .telefono {
    display: block;
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 24px;
}

.contDataContactenos .direccionContactenos {
    color: #585858;
    font-family: 'Roboto';
    font-size: 16px;
    margin-top: 7px;
    display: block;
}

.contBloqueContactenos {
    margin-left: 10%;
    margin-right: 0%;
    margin-top: 25px;
    vertical-align: top;
}

.contFormularioContactenos {
    min-height: 190px;
    padding: 6px 15px 0px 15px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    padding: 50px 25px;
    margin-top: 40px;
}

.contFormularioContactenosHtml {
    min-height: 190px;
    padding: 6px 15px 0px 15px;
    margin-bottom: 20px;
    padding: 50px 25px;
}

.tituloContactenos {
    font-family: 'Bree Rg Bold';
    font-size: 16px;
}


/* fin contactenos */


/* inicio menu */


/* fin menu */

#cd-shadow-layer {
    position: fixed;
    min-height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(76 75 75 / 60%);
    cursor: pointer;
    z-index: 1020;
    display: none;
}

#cd-shadow-layer.is-visible {
    display: block;
    -webkit-animation: cd-fade-in .3s;
    -moz-animation: cd-fade-in .3s;
    animation: cd-fade-in .3s;
}

#cd-cart.speed-in {
    right: 0;
}

#cd-cart {
    right: -100%;
    background: #fff;
    -webkit-transition: right .3s;
    -moz-transition: right .3s;
    transition: right .3s;
    position: fixed;
    top: 0;
    height: 100%;
    width: 375px;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    z-index: 999999999999;
    overflow-y: auto;
}

.ordenNo {
    color: #000;
    font-family: 'Roboto-Medium';
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.headerMenu {
    background-color: #8FD400;
    padding: 10px 15px;
}

.txNuevaOrden {
    font-family: 'Bree Rg Bold';
    font-size: 20px;
    color: #fff;
}

.txFecha {
    font-family: 'Roboto';
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.headerMenu .closeModal {
    filter: brightness(0) invert(1);
}

.txDetalle {
    font-family: 'Bree Rg';
    font-size: 16px;
    color: #585858;
}

.contCarritoProducto {
    padding: 15px 15px;
}

.contCarritoProducto hr {
    margin: 5px 0px;
}

.contCarritoProducto .txCosto {
    float: left;
    color: #808080;
    font-family: 'Roboto';
    font-size: 14px;
    margin-top: 5px;
}

.contCarritoProducto .txMonto {
    float: right;
    color: #8FD400;
    font-family: 'Roboto-Medium';
    font-size: 18px;
}

.contCarritoProducto .contCostoMonto {
    padding: 10px 0px;
    float: left;
    width: 100%;
}

.contCarritoProducto .imgProducto {
    width: 100px;
    height: 132px;
    margin-right: 10px;
    flex-grow: 0;
    flex-shrink: 0;
}

.imgBeneficio {
    width: 60px;
    height: 60px;
    margin-right: 30px;
    margin-left: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.contProducto {
    padding: 10px 0px;
    display: flex;
    position: relative;
}

.contProductoBotella {
    border: 1px solid #C4C4C4;
    border-radius: 6px;
    margin-top: 20px;
}

.contProductoBotella .nameProducto {
    color: #585858 !important;
    font-size: 18px !important;
}

.contProductoBotella .toppings {
    font-size: 13px !important;
    margin-bottom: 10px;
    margin-right: 15px;
}

.contProductoBotella .contTotalProducto {
    color: #585858 !important;
}

.contProducto .contInfo {
    width: -webkit-fill-available;
}

.contProducto .contInfo .nameProducto {
    color: #8FD400;
    font-family: 'Bree Rg';
    font-size: 18px;
    padding-right: 25px;
    padding-left: 0px;
}

.contProducto .contInfo .toppings {
    color: #585858;
    font-family: 'Roboto';
    font-size: 14px;
}

.contProducto .contInfo .toppings span {
    font-family: 'Roboto-Medium';
    color: #585858;
}

.contProducto .contInfo ul {
    font-family: 'Roboto-Medium';
    color: #000;
}

.contProducto .contInfo ul {
    padding-left: 40px;
    margin-top: 10px;
}

.contProducto .contInfo ul li {
    list-style-type: disc;
    padding: 0px;
    font-family: 'Roboto';
    font-size: 12px;
    color: #585858;
}

.contProducto .contInfo .contCantidad {
    display: flex;
}

.contCantidad .contEditAddress {
    position: absolute;
    top: 10px;
    right: 10px;
}

.contDeleteBeneficio {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.contTotalDescuento {
    margin-top: 20px;
    padding: 5px 20px;
    font-family: 'Bree Rg';
}

.contMenos {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #F5F8FF;
    border: 0.5px solid #BDBBBB;
    text-align: center;
    line-height: 27px;
    color: #585858;
    cursor: pointer;
    font-family: 'Bree Rg Bold';
}

.txCantidad {
    text-align: center;
    color: #585858;
    font-family: 'Bree Rg';
    width: 30px;
    line-height: 30px;
}

.contTotalProducto {
    color: #8FD400;
    font-family: 'Roboto-Medium';
    font-size: 18px;
    line-height: 30px;
    margin-left: 20px;
}

.contFormularioContactenos .contTotalProducto {
    color: #585858;
    font-family: 'Roboto-Medium';
    font-size: 14px;
    margin-right: 5px;
    line-height: 30px;
    margin-left: 20px;
    margin-top: 40px;
    text-align: right;
}

.imagenesIlustrativa {
    font-family: 'Roboto';
    color: #585858;
    font-size: 12px;
}

#popup-login {
    top: 40px;
}

.imagenesIlustrativaTake {
    font-family: 'Roboto';
    color: #585858;
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    right: 30px;
}

.conTotalCarrito {
    text-align: center;
    color: #585858;
    font-size: 18px;
    font-family: 'Bree Rg';
    margin-top: 20px;
}

.conTotalCarrito span {
    font-size: 24px;
}

.contProducto .contInfo .contEditAddress {}


/* inicio cuenta */

.contName {
    position: relative;
    margin-top: 40px;
    color: #585858;
    font-family: 'Bree Rg Bold';
    font-size: 20px;
}

.contEdit {
    position: absolute;
    top: 5px;
    right: 0px;
    font-size: 14px;
    font-family: 'Roboto';
    cursor: pointer;
}

.contCuenta hr {
    margin: 5px 0px;
}

.contCuenta .rowRegistro ul {
    padding-left: 40px;
    margin-top: 20px;
}

.contCuenta .rowRegistro ul li {
    list-style-type: disc;
    padding: 10px 0px;
    font-family: 'Roboto';
}

.btCambiarPass {
    cursor: pointer;
    padding: 10px 0px 10px 20px;
}

.btConsulta {
    height: 60px;
    font-size: 16px;
    font-family: 'Bree Rg Bold';
}

.btRegistrateClub {
    height: 60px;
    font-size: 16px;
    background-color: #585858;
    font-family: 'Bree Rg Bold';
}

.btRegistrateClub:hover {
    background-color: #3a3a3a !important;
}

.btRegistrateClub:focus {
    background-color: #3a3a3a !important;
}

.soloUnMinuto {
    font-size: 13px;
    font-family: 'Bree Lt';
}

.ReactModal__Content {
    border-radius: 10px !important;
}

.btConoceBeneficios {
    color: #8FD400;
    font-family: 'Roboto';
    width: 100%;
    text-align: center;
    display: block;
    padding: 20px 0px;
}

.btConoceBeneficios:hover {
    color: #8FD400;
}

.btConoceBeneficios:hover:not(.active) {
    color: #8FD400;
}

.btConoceBeneficios:active {
    color: #8FD400;
}

.btConoceBeneficios:focus {
    color: #8FD400;
}

.contTitleDireccion {
    color: #8FD400;
    font-family: 'Bree Rg Bold';
    font-size: 18px;
    float: left;
}

.contEditAddress {
    font-size: 14px;
    font-family: 'Roboto';
    cursor: pointer;
    float: right;
    margin-top: 4px;
}

.contDireccionText {
    min-height: 40px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    padding: 20px 25px;
    margin-top: 5px;
}

.contDireccionMain {
    margin: 40px 20px 0px 50px;
}

.contDireccion {
    margin-top: 50px;
}


/* fin cuenta */


/* inico detalle orden */

.contListDirecciones {
    display: flex;
    padding-top: 10px;
}

.iconMetodoPago {
    width: 35px;
    margin-left: 15px;
}

.iconTarjetaVM {
    width: 35px;
    margin-left: 10px;
}

.rowRegistro .tituloCampo {
    margin-top: 0px;
    font-family: 'Roboto';
}

.inputTarjeta {
    padding-left: 60px !important;
}

.contTarjeta {
    position: relative;
}

.iconTarjeta {
    width: 30px;
    position: absolute;
    left: 15px;
    top: 17px;
}

.itemMetodoPago {
    font-family: 'Roboto-Medium';
    color: #585858;
    font-size: 13px;
    display: flex;
    margin-top: 25px;
    border: 1px solid #AFAFAF;
    border-radius: 8px;
    margin-right: 20px;
    padding: 15px;
    align-items: center;
    cursor: pointer;
    min-height: 67px;
}

.itemMetodoPago label {
    cursor: pointer;
}

.itemDireccion {
    font-family: 'Roboto-Medium';
    color: #585858;
    font-size: 13px;
    display: flex;
    margin-right: 20px;
    align-items: center;
}

.itemDireccionLink {
    display: flex;
    color: #8FD400;
    cursor: pointer;
}

.itemDireccionLink:hover {
    text-decoration: underline;
}

.itemDireccionLink a {
    font-family: 'Roboto-Medium';
    color: #8FD400;
    font-size: 13px;
    display: block;
    margin-right: 20px;
    align-items: center;
}

.contDetalleDireccion {
    padding-left: 25px;
    margin-top: 30px;
}

.contItemDetalle {
    display: flex;
    margin-top: 15px;
}

.contItemDetalle .itemTitulo {
    color: #7D7D7D;
    font-family: 'Roboto-Medium';
    font-size: 14px;
    width: 110px;
    flex-grow: 0;
    flex-shrink: 0;
}

.contItemDetalle .itemValor {
    color: #585858;
    font-family: 'Roboto-Medium';
    font-size: 14px;
}

.lineaDirecciones {
    border-top: 1px dashed #BFBFBF;
    margin-top: 25px;
    margin-bottom: 25px;
}

.iconMas {
    width: 20px;
    height: 20px;
}

.btnConfirmar {
    width: 200px;
    margin: 0px auto;
    display: block;
    float: right;
    margin-top: 20px;
    height: 40px;
    font-family: 'Roboto-Medium';
}

.btnCancelar {
    width: 200px;
    margin: 0px auto;
    display: block;
    float: right;
    margin-top: 20px;
    font-family: 'Roboto-Medium';
    color: #585858;
    background-color: #00000000;
    height: 40px;
}

.btnCancelar:hover {
    color: #585858 !important;
    background-color: #00000000 !important;
}

.btnCancelar:hover {
    color: #585858 !important;
    background-color: #00000000 !important;
}

.btnCancelar:focus {
    color: #585858 !important;
    background-color: #00000000 !important;
}

.contEnvioCosto {
    background-color: #ECECEC;
    border-radius: 8px;
    padding: 5px 15px;
    color: #585858;
    float: left;
    width: 100%;
}

.txEnvioDomicilio {
    float: left;
    font-family: 'Roboto-Medium';
    font-size: 13px;
}

.txCostoEnvio {
    float: right;
    font-family: 'Roboto';
    font-size: 14px;
}

.txTotalOrden {
    color: #8FD400;
    font-family: 'Roboto-Medium';
    font-size: 20px;
    float: right;
    width: 100px;
    margin-top: 10px;
    text-align: right;
}

.txTotalBeneficio {
    color: #585858;
    font-family: 'Roboto-Medium';
    font-size: 13px;
    float: right;
    width: 100px;
    margin-top: 10px;
    text-align: right;
}


/* fin detalle orden */


/* inicio track */

.ReactModal__Overlay {
    opacity: 0;
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.contPasos {
    width: 250px;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

.productosTrack .imgProducto {
    width: 80px;
    height: 105px;
}

.lineaTrackFull {
    background-color: #C5C5C5;
    width: 1px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    padding: 0px;
    left: 53%;
}

.productosTrack .contInfo ul {
    margin-top: 0px;
    margin-bottom: 0px;
}

.estadoOrden {
    color: #9F9F9F;
    font-size: 18px;
    font-family: 'Bree Rg';
}

.estadoOrden span {
    color: #585858;
}

.circuloPasoCompletado {
    width: 75px;
    height: 75px;
    background-color: #8FD400;
    border-color: #ccc;
    border-width: 0px;
    border-radius: 45px;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.icon_ingresada {
    width: 40px;
    height: 40px;
    filter: brightness(0) invert(1);
    margin-top: 20%;
}

.icon_preparacion {
    width: 38px;
    height: 38px;
    margin-top: 20%;
}

.imgBlanco .icon_preparacion {
    filter: brightness(0) invert(1);
}

.icon_camino {
    width: 45px;
    height: 45px;
    margin-top: 20%;
}

.imgBlanco .icon_camino {
    filter: brightness(0) invert(1);
}

.icon_entregado {
    width: 40px;
    height: 40px;
    margin-top: 20%;
}

.imgBlanco .icon_entregado {
    filter: brightness(0) invert(1);
}

.contTextoPaso {
    justify-content: center;
    margin-left: 20px;
    text-align: left;
}

.txHora {
    color: #585858;
    font-size: 14px;
    font-family: 'Roboto-Medium';
}

.txNombreOrdenActivo {
    color: #8FD400;
    font-size: 16px;
    font-family: 'Bree Rg';
}

.txNombreOrden {
    color: #ACACAC;
    font-size: 16px;
    font-family: 'Bree Rg';
}

.espacioLinea {
    width: 250px;
    height: 30px;
    margin: 0px auto;
}

.lineaVerticalTrack {
    width: 2px;
    height: 30px;
    margin-left: 38px;
    background-color: #DEDEDE;
}

.mensajeTrack {
    color: #585858;
    font-size: 16px;
    font-family: 'Bree Rg';
}

.circuloPendiente {
    background-color: #F1F1F1;
}

.circuloActivo {
    background-color: #8FD400;
}

.detalleOrden {
    color: #585858;
    font-family: 'Bree Rg Bold';
    font-size: 14px;
    margin-top: 20px;
}


/* fin track */


/* inicio llallaoclub */

.nombreProductoLeal {
    color: #585858;
    font-size: 18px;
    font-family: 'Bree Rg Bold';
    text-align: center;
}

.puntoProductoLeal {
    color: #8FD400;
    font-size: 24px;
    font-family: 'Bree Rg Bold';
    margin-bottom: 5px;
    text-align: center;
}

.itemProductoLeal {
    padding: 6px 15px 0px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin: 10px 10px 25px 10px;
    background-color: #fff;
    min-height: 235px;
}

.tienes {
    color: #fff;
    font-size: 20px;
    font-family: 'Bree Rg Bold';
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 22%;
}

.cantidadPuntosLeal {
    color: #fff;
    font-size: 60px;
    font-family: 'Bree Rg Bold';
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 15%;
}

.puntosLeal {
    color: #fff;
    font-size: 28px;
    font-family: 'Bree Rg Bold';
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 5%;
}

.contTusPuntos {
    font-family: 'Roboto';
    color: #585858;
    font-size: 14px;
    margin-top: 20px;
}

.contClub ul {
    margin-top: 10px;
}

.contClub ul li {
    color: #8FD400;
    font-family: 'Roboto';
    list-style-type: disc;
}

.contClub li {
    padding: 3px 0px;
}


/* fin llallaoclub */


/* inicio orden finalizada */

.txTuOrden {
    color: #8FD400;
    font-family: 'Bree Rg Bold';
    font-size: 24px;
}

.txHemos {
    color: #585858;
    font-family: 'Roboto-Medium';
    font-size: 15px;
}

.btnLink {
    padding-top: 10px;
    text-decoration: none !important;
}

.btnLink:hover {
    color: #fff;
}

.btnLink:active {
    color: #fff;
}

.btnLink:focus {
    color: #fff;
}


/* fin orden finalizada */

.tarrina {
    position: absolute;
    width: 95%;
    height: 100%;
    margin-left: 5%;
}

.frozen-image2 {
    position: absolute;
    width: 81%;
    top: 11%;
    left: 6%;
    z-index: 1;
}

.categoria1 {
    width: 82%;
    margin-top: -2%;
    margin-left: 7%;
}

.categoria2 {
    width: 100%;
    margin-top: 2%;
}

.categoria3 {
    width: 54%;
    margin-top: -20%;
    margin-left: 23%;
}

.categoria4 {
    width: 100%;
    margin-top: -18%;
    margin-left: 0%;
}

.categoria4.Salsas {
    width: 64%;
    margin-top: -66%;
    margin-left: 18%;
}

.categoria4.Crunches {
    width: 64%;
    margin-top: -66%;
    margin-left: 18%;
}

.categoria4.Frutas {
    width: 64%;
    margin-top: -66%;
    margin-left: 18%;
}

.categoria4.Granizados {
    width: 100%;
    margin-top: -13%;
    margin-left: 0%;
}

.frozen-imageAnt {
    position: absolute;
    width: 88%;
    top: 7%;
    left: 4%;
    z-index: 1;
}

.frozen-image {
    position: absolute;
    width: 106%;
    top: 10%;
    left: -5%;
    z-index: 1;
}

.frozen-image img {
    display: block;
    margin: 0 auto;
    width: 100%
}

.csstransforms .frozen-image {
    position: absolute;
    width: 97%;
    top: 41%;
    left: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, -34%);
    -moz-transform: translate(-50%, -34%);
    -ms-transform: translate(-50%, -34%);
    -o-transform: translate(-50%, -34%);
    transform: translate(-50%, -34%);
}

.animateRemove {
    height: 0px;
    transition: height 11s;
}

.contToppingsCarrito .react-tabs__tab {
    background-color: #F8F8F8 !important;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px #C4C4C4 solid;
    border-right: 1px #C4C4C4 solid;
    border-left: 1px #C4C4C4 solid;
    border-bottom: 1px #C4C4C4 solid;
    color: #8D8D8D !important;
    font-size: 13px;
}

.contToppingsCarrito .react-tabs__tab--selected {
    background-color: #8FD400 !important;
    color: #fff !important;
    border-bottom: 0px !important;
}

.contToppingsCarrito .react-tabs__tab-panel {
    background-color: #F8F8F8 !important;
    border: 1px solid #C4C4C4;
    border-radius: 6px;
    margin-bottom: 10px;
    box-shadow: none !important;
}

.contToppingsCarrito .react-tabs__tab-list {
    margin-left: 10px !important;
}

.react-swipeable-view-container>div {
    overflow: hidden !important;
}


/* precio delivery */

.contPrecioDelivery {
    border: 1px solid #8FD400;
    min-height: 65px;
    width: 100%;
    display: flex;
    right: 0px;
    top: -85px;
    position: absolute;
    border-radius: 10px;
    background-color: #fff;
}

.moto {
    height: 40px;
    margin-top: 10px;
    margin-left: 20px;
    display: inline-block;
}

.contTextoPromoDelivery {
    display: inline-block;
    margin-top: 12px;
    margin-left: 15px;
}

.tituloPromoDelivery {
    color: #8FD400;
    font-family: 'Bree Rg Bold';
    font-size: 20px;
    line-height: 1;
}

.descripcionPromoDelivery {
    color: #979797;
    font-family: 'Roboto';
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* fin precio delivery */


/* inicio direccion header */

.listadoDirecciones {
    margin-top: 20px;
}

.listadoDirecciones .radioDireccion {
    position: relative;
    visibility: visible;
    opacity: 1;
    left: 10px;
    width: 15px;
    height: 15px;
    margin-top: 24px;
}

.listadoDirecciones .contDireccion {
    margin-top: 0px;
    display: flex;
    cursor: pointer;
}

.contDireccionInfo {
    min-height: 40px;
    box-shadow: 2px 4px 10px 2px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 2px 4px 10px 2px rgb(0 0 0 / 12%);
    -moz-box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    padding: 6px 15px;
    margin-top: 5px;
    width: 100%;
    margin-left: 30px;
}

.contDireccionInfo .contTitleDir {
    color: #8FD400;
    font-family: 'Bree Rg Bold';
    font-size: 18px;
}

.linkDirecciones {
    color: #585858;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Roboto';
    display: block;
    margin-top: 20px;
    border: 1px solid #8fd400;
    padding: 5px 15px;
    border-radius: 6px;
    margin-right: 20px;
    clear: both;
    margin-bottom: 15px;
}

.ReactModal__Content {
    overflow: unset !important;
}

.contInfoDireccion {
    padding: 10px 20px;
}

.itemDireccion {
    font-family: 'Bree Rg';
    font-size: 16px;
}

.itemDireccionTienda {
    font-family: 'Bree Rg Bold';
    font-size: 16px;
    color: #8fd400;
}

.tituloItemDireccion {
    color: #8a8787;
}


/* fin direccion header */


/* inicio promocion */

.textPromocion {
    color: #585858;
    font-size: 16px;
    font-family: 'Roboto-Medium';
    margin-top: 20px;
    font-weight: bold;
    display: block;
}

.tabsPromociones {
    margin-top: 10px;
}

.tabsPromociones .react-tabs__tab--selected {
    width: 50%;
    text-align: center;
    color: #585858;
    font-size: 24px;
    border-bottom: 3px solid #8FD400 !important;
}

.tabsPromociones .react-tabs__tab {
    width: 50%;
    text-align: center;
    font-size: 24px;
}

.tabsCategorias {
    margin-top: 10px;
}

.tabsCategorias .react-tabs__tab--selected {
    background: #8FD400;
    background-color: #8FD400 !important;
    color: #fff !important;
    width: auto;
    text-align: left;
    font-size: 16px;
    border-bottom: 0px !important;
}

.tabsCategorias .react-tabs__tab {
    width: auto;
    text-align: left;
    font-size: 16px;
    margin-right: 10px;
}

.seleccionarTamano {
    font-family: 'Roboto';
    font-size: 15px;
    margin-top: 20px;
    color: #585858;
}

.itemTarrinaPromo {
    background-color: #F3F3F3;
    cursor: pointer;
    border: 1px solid #F3F3F3;
}

.itemSelect {
    border: 1px solid #a29e9e !important;
}

.contTarrinasPromo {}

.contTarrinasPromo .itemTarrinaPromo {
    width: 30%;
    margin-left: 5%;
    border-radius: 8px;
    padding-bottom: 10px;
}

.contTarrinasPromo .itemTarrinaPromo:first-child {
    width: 30%;
    margin-left: 0%;
}


/* fin promocion */

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes fadeInOut {
    0% {
        opacity: 0
    }
    25% {
        opacity: 1
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(0%, 0%)
    }
}

@-moz-keyframes fadeInOut {
    0% {
        opacity: 0
    }
    25% {
        opacity: 1
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0;
        -moz-transform: translate(0%, 0%)
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0
    }
    25% {
        opacity: 1
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%)
    }
}

@-webkit-keyframes toppingDrop {
    0% {
        -webkit-transform: translate(0, -100%);
        opacity: 0
    }
    10% {
        opacity: 1
    }
    90% {
        opacity: 1
    }
    100% {
        -webkit-transform: translate(0, 0%);
        opacity: 0
    }
}

@-moz-keyframes toppingDrop {
    0% {
        -moz-transform: translate(0, -100%);
        opacity: 0
    }
    10% {
        opacity: 1
    }
    90% {
        opacity: 1
    }
    100% {
        -moz-transform: translate(0, 0%);
        opacity: 0
    }
}

@keyframes toppingDrop {
    0% {
        -webkit-transform: translate(0, -100%);
        -moz-transform: translate(0, -100%);
        -ms-transform: translate(0, -100%);
        -o-transform: translate(0, -100%);
        transform: translate(0, -100%);
        opacity: 0
    }
    10% {
        opacity: 1
    }
    90% {
        opacity: 1
    }
    100% {
        -webkit-transform: translate(0, 0%);
        -moz-transform: translate(0, 0%);
        -ms-transform: translate(0, 0%);
        -o-transform: translate(0, 0%);
        transform: translate(0, 0%);
        opacity: 0
    }
}

.cssanimations .basic-topping>img.drop-img {
    -webkit-animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    -moz-animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both
}

.cssanimations .basic-topping>img.drop-img+img.drop-img {
    -webkit-animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    -moz-animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.15s;
    -moz-animation-delay: 0.15s;
    animation-delay: 0.15s
}

.cssanimations .basic-topping>img.drop-img+img.drop-img+img.drop-img {
    -webkit-animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    -moz-animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: toppingDrop 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    animation-delay: 0.3s
}

.cssanimations .basic-topping img.topping {
    -webkit-animation: fadeIn 0.8s cubic-bezier(0.47, 0, 0.745, 0.715);
    -moz-animation: fadeIn 0.8s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: fadeIn 0.8s cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s
}

.cssanimations .fadeIn img.topping {
    -webkit-animation: fadeIn 0.8s cubic-bezier(0.47, 0, 0.745, 0.715);
    -moz-animation: fadeIn 0.8s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: fadeIn 0.8s cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s
}

.cssanimations .final-topping .topping-fade {
    -webkit-animation: fadeIn 0.95s cubic-bezier(0.47, 0, 0.745, 0.715);
    -moz-animation: fadeIn 0.95s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: fadeIn 0.95s cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s
}

.cssanimations img[data-builder-img].fadeIn,
.cssanimations .fadeIn {
    -webkit-animation: fadeIn 0.5s ease-in;
    -moz-animation: fadeIn 0.5s ease-in;
    animation: fadeIn 0.5s ease-in;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s
}

.cssanimations img.topping.fadeIn {
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    animation-delay: 0.3s
}

.cssanimations img[data-builder-img].fadeOut,
.cssanimations .fadeOut {
    -webkit-animation: fadeOut 0.5s ease-in;
    -moz-animation: fadeOut 0.5s ease-in;
    animation: fadeOut 0.5s ease-in;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both
}

.cssanimationsMovil img[data-builder-img].fadeIn,
.cssanimationsMovil .fadeIn {
    -webkit-animation: fadeIn 0.2s ease-in;
    -moz-animation: fadeIn 0.2s ease-in;
    animation: fadeIn 0.2s ease-in;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s
}

.cssanimationsMovil img[data-builder-img].fadeOut,
.cssanimationsMovil .fadeOut {
    -webkit-animation: fadeOut 0.2s ease-in;
    -moz-animation: fadeOut 0.2s ease-in;
    animation: fadeOut 0.2s ease-in;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both
}

.cssanimations img[data-builder-img].fadeInOut,
.cssanimations .fadeInOut {
    -webkit-animation: fadeInOut 2s ease-in;
    -moz-animation: fadeInOut 2s ease-in;
    animation: fadeInOut 2s ease-in;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both
}

.initiallyVisible img {
    opacity: 1
}

.transparent {
    opacity: 0
}

.no-opacity .transparent {
    visibility: hidden
}

.frozen-toppings img {
    padding: 0;
    max-width: 100%
}

.frozen-toppings {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%
}

.topping-drop {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    will-change: opacity, transform;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

img[data-builder-img] {
    position: absolute;
    top: 0;
    left: 0;
    will-change: opacity, transform
}

label.error {
    padding-top: 8px;
    font-size: 0.875em;
    color: #df2241;
    text-transform: inherit;
    font-family: 'Bree Rg';
}

label.errorLogin {
    padding-top: 8px;
    font-size: 12px !important;
    color: #df2241 !important;
    text-transform: inherit;
    padding-bottom: 20px;
    font-family: 'Bree Rg';
}

.crearCuentaLinks {
    margin-top: 20px;
}

.errorFormulario {
    padding-top: 8px;
    font-size: 14px;
    color: #df2241;
    text-align: center;
    font-family: 'Bree Rg Bold';
}

.error input[type="text"],
input[type="text"].error,
.error input[type="password"],
input[type="password"].error,
.error input[type="tel"],
input[type="tel"].error,
.error input[type="search"],
input[type="search"].error,
.error input[type="email"],
input[type="email"].error,
.error input[type="number"],
input[type="number"].error,
.error input[type="date"],
input[type="date"].error,
.error input[type="time"],
input[type="time"].error,
.error textarea,
textarea.error,
.error select,
select.error {
    border-color: #df2241;
    background: #ffe6e8;
    margin-bottom: 0px;
}

.error input[type="text"],
input[type="text"].error:focus,
.error input[type="password"],
input[type="password"].error:focus,
.error input[type="tel"],
input[type="tel"].error:focus,
.error input[type="search"],
input[type="search"].error:focus,
.error input[type="email"],
input[type="email"].error:focus,
.error input[type="number"],
input[type="number"].error:focus,
.error input[type="date"],
input[type="date"].error:focus,
.error input[type="time"],
input[type="time"].error:focus,
.error textarea,
textarea.error,
.error select,
select.error {
    border-color: #df2241;
    background: #ffe6e8;
}

input[type="radio"]+label.checkboxCustom {
    display: inline-block;
    float: left;
    min-width: 24px;
    min-height: 24px;
    margin-top: 0;
    margin-right: 4px;
    background: url("assets/images/llaollao-icon.svg");
    background-position: -5px -76px;
    background-size: 110px;
    padding: 0;
    cursor: pointer;
}

input[type="checkbox"]+label.checkboxCustom,
input[type="checkbox"]+label+label.checkboxCustom {
    display: inline-block;
    float: left;
    min-width: 24px;
    min-height: 24px;
    margin-top: 0;
    margin-right: 4px;
    background: url("assets/images/llaollao-icon.svg");
    background-position: -5px -102px;
    background-size: 110px;
    padding: 0;
    cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

input[type="checkbox"]:checked+label.checkboxCustom,
input[type="checkbox"]:checked+label+label.checkboxCustom {
    background-position: -39px -102px;
}

input[type="radio"]:checked+label.checkboxCustom {
    background-position: -39px -76px;
}

input[type="text"],
input[type="password"],
input[type="file"],
input[type="tel"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="time"],
textarea,
select {
    margin-bottom: 23px;
    margin-top: 5px;
    padding: 0.3em;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    background-color: #F7F7F7;
    border: 1px solid #CFCFCF;
    font-family: 'Roboto';
    font-size: 1.125em;
}

textarea {
    width: 100%;
    min-height: 64px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
textarea:focus,
select:focus {
    box-shadow: 0px 0px 5px 0px rgba(98, 98, 98, 0.75);
    outline: none;
    border-color: #8FD400
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    #navbar div a {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 12px;
    }
    .logo-fb {
        width: 12px;
    }
    .logo-ins {
        width: 25px;
    }
    
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}